* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

cloudflare-app {
  display: none !important;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB',
    'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $black-1 !important;
  height: 100%;
}

.nbfont {
  font-family: 'DIN Condensed';
  font-weight: bold;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.04em;
}

html {
  height: 100%;
}


#root {
  height: 100%;
  position: relative;
}

.content-wrapper {
  max-width: 595px;
  margin: 0 auto;
  background: #ffffff;
  padding: 25px;
  height: 100%;
  position: relative;

  @media (max-width: 374.98px) {
    padding: 15px;
  }

}




@mixin xl {
  @media (min-width: 1280.98px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 1024.98px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768.98px) {
    @content;
  }
}

@mixin sm {
  @media (min-width: 566.98px) {
    @content;
  }
}
