.credits-wrapper {
  background: #15152D;
  height: initial;

  .title {
    margin: 32px 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFFFFF;

    h4 {
      font-size: 16px;
      font-weight: 500;
    }

    a {
      font-size: 13px;
      color: #7b7b7b;
    }

    span {
      font-size: 13px;
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 26px;
    border: 0.5px solid #7681FF;
    border-radius: 20px;
    font-size: 12px;
    color: #7681FF;
    margin-left: auto;

    &.btn-disable {
      filter: grayscale(1);
      opacity: 0.4;
    }
  }

  .item {
    display: flex;
    align-items: center;
    background: #242446;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    margin-top: 12px;
    padding: 0 12px;
  }

  .credit {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cards {
      display: flex;
      height: 82px;
      flex-direction: column;
      justify-content: center;
      width: 48%;
      background: #242446;
      box-shadow: 0px 12px 34px rgba(0, 0, 0, 0.04);
      border-radius: 4px;
      padding: 16px 14px;
      position: relative;

      &:nth-child(1)::after,
      &:nth-child(2)::after {
        content: "";
        display: block;
        width: 50px;
        height: 60px;
        border-radius: 4px;
        background: url("https://malus.s3cdn.net/uploads/icon_mask_1.png") no-repeat;
        background-size: cover;
        position: absolute;
        right: 0;
        bottom: 0;
        opacity: 0.6;
      }

      &:nth-child(2)::after {
        background: url("https://malus.s3cdn.net/uploads/icon_mask_app.png");
        background-size: cover;
        opacity: 1 !important;
      }

      .nb {
        font-size: 26px;
        color: #FFFFFF;

        small {
          font-size: 16px;
          color: rgba(255, 255, 255, 0.6);
        }
      }

      span {
        font-size: 12px;
        color: #7b7b7b;
      }

    }
  }

  .tasks {
    .item {
      height: 60px;

      i {
        font-size: 24px;
        background: linear-gradient(320.71deg, #FFB801 50%, #FFE08F 92.86%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .nb {
        font-size: 18px;
        margin-left: 8px;
        margin-top: 6px;
        color: #FFFFFF;
      }

      .name {
        margin-left: 24px;
        font-size: 15px;
        color: rgba(255, 255, 255, 0.6);
        font-weight: 500;
      }

      .progress {
        font-size: 15px;
        color: rgba(255, 255, 255, 0.6);
        margin-left: 8px;
      }

    }
  }

  .exchange {

    .item {
      height: 72px;

      &:last-child {
        margin-bottom: 64px;
      }

      &.disable {

        i {
          color: #C5C7E9;
        }
      }

      .name {
        font-size: 15px;
        color: rgba(255, 255, 255, 0.6);
        font-weight: 500;
        width: 90px;
      }

      i {
        font-size: 24px;
        color: #7681FF;
        margin: 0 16px 0 8px;
      }

      .nb {
        font-size: 15px;
        font-weight: 500;
        color: #FFFFFF;
      }
    }
  }

  .detail {
    .item {
      height: 60px;
      margin-top: 0;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 64px;
      }

      &.item-add {
        i {
          background: linear-gradient(320.71deg, #FFB801 50%, #FFE08F 92.86%);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      &.item-subtract {
        i {
          color: #7681FF;
        }
      }

      i {
        font-size: 24px;
      }

      .nb {
        font-size: 16px;
        margin: 5px 0 0 8px;
        color: #FFFFFF;
      }

      .name {
        font-size: 15px;
        color: #FFFFFF;
        font-weight: 500;
        margin-left: 16px;
      }

      .time {
        margin-left: auto;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
}
