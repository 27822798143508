body {
  @include lg {
    background: #f9f9f9 !important;
  }
}

.support-container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 24px;

  @include lg {
    padding: 0;

    &.support-container-content {
      max-width: 950px;
      min-height: calc(100vh - 198px);
    }
  }
}

.loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.6);

  margin-top: 40%;
  display: none;

  @include lg {
    margin-top: 10%;
    color: $black-3;
  }

  i {
    animation: rotation 1s infinite linear;
    font-size: 40px;
  }
}


.block-loading {
  .loading {
    display: flex;
  }
}

// support header

.support-header {
  border-bottom: 1px solid #f4f4f4;
  display: none;

  .header-nav {
    display: flex;
    align-items: center;
    height: 64px;

    .brand {
      display: flex;
      align-items: center;
      img{
        width: 106px;
      }

      .sub-title {
        font-size: 18px;
        padding-left: 8px;
        margin-left: 8px;
        border-left: 2px solid #d5d5d5;
        line-height: 17px;
        color: #333333;
        font-weight: 500;

      }
    }

    .nav-link {
      margin-left: auto;
      display: flex;
      align-items: center;

      .btn-ticket {
        margin-right: 48px;
        color: $black-1;
        display: flex;
        align-items: center;

        i {
          margin-right: 4px;
          font-size: 16px;
        }
      }

      .btn-dashboard {
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        transition: all .2s ease;

        &:hover {
          span {
            text-decoration: underline;
          }
        }

       i{
         color: $black-1;
       }

        span {
          font-size: 14px;
          color: #555555;
          margin-left: 8px;
        }
      }
    }
  }

  @include lg {
    display: block;
  }
}

// support-footer

.support-footer {
  display: none;
  background: #f8f8f8;

  @include lg {
    display: block;
  }

  .footer {
    height: 64px;
    display: flex;
    align-items: center;

    .left {
      span {
        font-size: 12px;
        color: #7b7b7b;
      }
    }

    .right-nav {
      display: flex;
      align-items: center;
      margin-left: auto;

      a {
        font-size: 12px;
        margin-left: 24px;
        color: #7b7b7b;
        cursor: pointer;
        transition: all .2s ease;

        &:hover {
          color: $black-1;
        }
      }
    }
  }
}


// navbar

.navbar {
  display: none;
  align-items: center;
  margin-top: 64px;

  .back {
    display: flex;
    color: $black-3;
    font-size: 32px;
    margin-right: 12px;
    padding-right: 12px;
    border-right: 2px solid #d5d5d5;
    line-height: 26px;
    cursor: pointer;

    i {
      margin-right: 6px;
    }
  }

  .title {
    font-size: 32px;
    color: #222222;
  }

  @include lg {
    display: flex;
  }
}

// content

.content {
  margin-top: 24px;

  @include lg {
    margin-top: 56px;
  }

  &.support-cate-content {
    .cates {
      display: flex;
      flex-direction: column;

      @include lg {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .card {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 24px 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.04);

        @include lg {
          padding: 20px 24px;
          background: none;
          border: 1px solid rgba(0,0,0,0.06);
          width: 48.5%;
          margin-bottom: 24px;
          cursor: pointer;
          transition: all .2s ease;
          border-radius: 8px;
          background: #ffffff;
          box-shadow: 0px 9px 34px rgba(0, 0, 0, 0.05);

          &:hover {
            box-shadow: 0px 20px 34px rgba(0, 0, 0, 0.08);
          }
        }

        .circle {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 44px;
          width: 44px;
          border-radius: 8px;
          border: 1px solid rgba(255, 255, 255, 0.1);
          flex-shrink: 0;

          i {
            font-size: 24px;
            color: rgba(255, 255, 255, 0.6);
          }

          @include lg {
            height: 56px;
            width: 56px;
            background: #fff7d6;

            i {
              color: $black-3;
            }
          }
        }

        .right {
          margin-left: 16px;

          span {
            display: block;

            &.title {
              font-size: 16px;
              color: rgba(255, 255, 255, 0.8);
              font-weight: 500;
            }

            &.desc {
              margin-top: 6px;
              font-size: 12px;
              color: rgba(255, 255, 255, 0.5);
            }

            @include lg {
              &.title {
                font-size: 18px;
                color: #222222;
              }

              &.desc {
                margin-top: 8px;
                font-size: 12px;
                color: #7b7b7b;
              }
            }
          }
        }
      }
    }

  }

  &.support-article-content {
    .title {
      font-size: 20px;
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 24px;

      @include lg {
        display: none;
      }
    }

    .articles {

      .no-data{
        color: rgba(255, 255, 255, 0.4);
        @include lg{
          color: $black-1;
        }
      }
      .article {
        height: 56px;
        display: flex;
        align-items: center;
        color: rgba(255, 255, 255, 0.8);
        box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.05);

        .icon-arrow {
          margin-left: auto;
          font-size: 20px;
          color: rgba(255, 255, 255, 0.4);
        }

        .icon-doc {
          display: none;
        }

        @include lg {
          height: 64px;
          box-shadow: inset 0px -1px 0px rgba(167, 167, 167, 0.25);
          color: #333333;
          font-size: 16px;
          cursor: pointer;

          .icon-arrow {
            margin-left: auto;
            font-size: 24px;
            color: $black-3;
          }

          .icon-doc {
            display: block;
            font-size: 20px;
            margin-right: 8px;
            color: $black-3;
          }
        }
      }

    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 48px;
      appearance: none;
      -webkit-appearance: none;
      border: none;
      border-radius: 4px;
      background: $turbo;
      font-size: 16px;
      color: $black-3;
      font-weight: 500;
      margin-top: 48px;
      cursor: pointer;
      transition: all .2s ease;

      @include lg {
        display: flex;
        width: 245px;
        float: right;
      }
    }

    .block-loading {
      button {
        display: none;
      }
    }
  }

  &.support-ticket-content {
    max-width: 490px;
    margin: 24px auto 0;

    .form {
      @include lg {
        margin-top: 56px;
      }

    }

    .form-group {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      position: relative;

      label {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.6);

        @include lg {
          color: #333333;
        }
      }

      i {
        position: absolute;
        right: 12px;
        top: 42px;
        color: rgba(255, 255, 255, 0.6);

        @include lg {
          color: #7b7b7b;
        }
      }
    }

    input {
      display: flex;
      width: 100%;
      height: 48px;
      border-radius: 4px;
      align-items: center;
      border: 1px solid rgba(255, 255, 255, 0.1);
      font-size: 13px;
      color: white;
      background: none;
      padding: 0 10px;
    }

    ::placeholder {
      color: rgba(255, 255, 255, 0.4);

      @include lg {
        color: #7b7b7b;
      }
    }

    textarea {
      width: 100%;
      height: 100px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      font-size: 13px;
      background: none;
      color: white;
      padding: 10px;
      resize: none;
      margin: 0;
      line-height: 1.6;
      border-radius: 4px;

      @include lg {
        height: 80px;
      }
    }

    select {
      width: 100%;
      height: 46px;
      font-size: 13px;
      padding: 0 10px;
      border-radius: 4px;
      color: white;
      background: none;
      -webkit-appearance: none;
      border: 1px solid rgba(255, 255, 255, 0.1);
    }


    input,
    textarea,
    select {
      margin-top: 12px;

      @include lg {
        border-color: #D6D6D6;
        color: #222222;
      }

      &:focus {
        border: 1px solid rgba(255, 255, 255, 0.4);

        @include lg {
          border-color: #FFCE02;
        }
      }
    }


    button {
      background: #FFCE02;
      color: $black-1;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 12px;
      height: 48px;
      margin-top: 24px;
      width: 100%;
      position: relative;
      appearance: none;
      border: none;
      border-radius: 4px;
      transition: all .2s ease;
      cursor: pointer;

      @include lg {
        height: 46px;

      }

      &.loading {
        background: #FFCE02;
        cursor: not-allowed;

        &::before {
          font-family: 'remixicon';
          content: "\EEC6";
          font-size: 18px;
          margin-right: 8px;
          animation: 1s linear rotation infinite;
        }
      }
    }
  }

  &.success-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 320px;
    margin: 64px auto 0;

    @include lg {
      border: 1px solid #dedede;
      padding: 32px 32px 48px 32px;
      max-width: 380px;
      margin-top: 100px;
      border-radius: 4px;
    }

    img {
      width: 48px;

      @include lg {
        width: 56px;
        margin-top: -58px;
      }
    }

    h3 {
      margin-top: 18px;
      font-size: 18px;
      color: rgba(255, 255, 255, 0.8);

      @include lg {
        margin-top: 24px;
        color: #222222;
        font-size: 24px;
      }
    }

    span {
      display: block;
      margin-top: 24px;
      text-align: center;
      font-size: 13px;
      line-height: 1.6;
      color: rgba(255, 255, 255, 0.6);

      @include lg {
        color: #7b7b7b;
      }
    }

    .btn-back {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $black-3;
      width: 50%;
      color: #ffffff;
      font-size: 16px;
      height: 42px;
      border-radius: 4px;
      cursor: pointer;
      border: none;
      appearance: none;
      transition: all .2s ease;

      @include lg {
        height: 40px;
        font-size: 15px;

        &:hover {
          background: #3a4af3;
        }
      }
    }

  }

}



@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
