.notification {

  .item {
    background: $black-2;
    border-radius: 4px;
    margin-bottom: 16px;
    transition: all .2s ease;

    &:hover {
      background: $black-3;

      .link {
        i {
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }

    img {
      width: 100%;
      height: 104px;
      border-radius: 4px 4px 0 0;
    }

    .title {
      font-size: 15px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.8);
      padding: 16px 16px 0 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    .des {
      font-size: 13px;
      color: rgba(255, 255, 255, 0.6);
      padding: 12px 16px 0 16px;
      line-height: 1.6;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .link {
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 8px 0 16px;
      border-top: 1px solid rgba(255, 255, 255, 0.02);
      height: 28px;

      .time {
        color: rgba(255, 255, 255, 0.4);
        font-size: 12px;
      }

      i {
        font-size: 18px;
        color: rgba(255, 255, 255, 0.2);
        transition: all .2s ease;
      }
    }



    &.no-link {
      background: $black-2;

      .link {
        i {
          display: none !important;
        }
      }
    }
  }

}
