.content-wrapper {
  &.vip {
    padding: 0;
  }

}

.tab {
  display: flex;
  align-items: center;
  width: 100%;
  background: $black-2;
  justify-content: space-around;
  padding: 0 32px;

  .item-tab {
    height: 48px;
    background: $black-2;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    img {
      width: 20px;
      margin-right: 4px;
      transform: translateY(1px);
    }

    span {
      color: rgba(255, 255, 255, 0.6);
    }

    &.active {
      border-bottom: 2px solid rgba(255, 255, 255, 0.6);

      span {
        color: #ffffff;
        font-weight: 500;
      }
    }

    &.base {
      &.active {
        border-bottom: 2px solid $turbo;
      }
    }

    &.game {
      &.active {
        border-bottom: 2px solid $game;
      }
    }
  }
}

.tab-body {
  padding: 32px 32px;

  .content {
    margin-top: 8px;
    .tr {
      display: flex;
      align-items: center;
      width: 100%;
      background: $black-2;
      height: 56px;
      border-radius: 100px;

      &:nth-child(even) {
        background: none;
      }

      i {
        width: 15%;
        text-align: center;
        color: rgba(255, 255, 255, 0.6);
        font-size: 18px;
        margin-left: 4px;
      }

      .title {
        padding-left: 12px;
        width: 45%;
        text-align: left;
        color: rgba(255, 255, 255, 0.8);
        &.game{
          color: #FFCC47;
        }
      }

      .right {
        width: 40%;
        text-align: right;
        color: rgba(255, 255, 255, 0.8);  
        padding-right: 24px;

        i {
          font-size: 18px;
        }

        &.true {
          i {
            color: #07BB07;
          }

        }

        &.false {
          i {
            color: #FF5E5E;
          }
        }
      }
    }
  }

}
